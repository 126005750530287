/* eslint-disable @typescript-eslint/brace-style */
import { useNavigate, useLocation } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import { Paged } from 'api/common/types/Page';
import ApplicationsService from 'api/applications/ApplicationsService';
import { ApplicationDto } from 'api/applications/models/ApplicationDto';
import { ApplicationSearchCriteria } from 'api/applications/models/ApplicationSearchCriteria';
import UsersService from 'api/users/UsersService';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Logger from 'common/services/Logger';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import styles from './ApplicationsScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import toast from 'react-hot-toast';
import { Button, Container, Dropdown, DropdownButton, Stack, Form, Card, Breadcrumb } from 'react-bootstrap';
import Loading from 'common/services/Loading';

type Props = {
};

const ApplicationsScreen: React.FC<Props> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [criteria, setCriteria] = useState<ApplicationSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'name'
    });
    const [applicationsPage, setApplicationsPage] = useState<Paged<ApplicationDto>>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasApplicationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_APPLICATIONS_WRITE']);
    const [filters, setFilters] = useState({
        allIn: ''
    });
    const getData = async () => {
        try {
            Loading.show();
            const page = await ApplicationsService.getList(criteria);
            setApplicationsPage(page);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get applications list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const renderTableActionCell = (row: ApplicationDto) => {
        return <DropdownButton id="dropdown-basic-button" title="..." size="sm" variant="outline-secondary">
            <Dropdown.Item onClick={() => navigate(`/application/details/${row.id}`)}>{t('common.details')}</Dropdown.Item>
            {hasApplicationsWritePolicy && <>
                <Dropdown.Item onClick={() => navigate(`/application/edit/${row.id}`)}>{t('common.edit')}</Dropdown.Item>
                <Dropdown.Item onClick={() => showRemoveItemDialog(row)}>{t('common.delete')}</Dropdown.Item>
            </>
            }
        </DropdownButton>
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<ApplicationDto>[] = [
        {
            field: 'name',
            name: t('applications.list.name') ?? '',
            onSearch: onTableFilter,
            searchField: 'name'
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        },
    ];

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<ApplicationDto | null>(null);

    const showRemoveItemDialog = async (item: ApplicationDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            if (event.ctrlKey) {
                window.open(location.pathname + '/details/' + id, '_blank');
                return;
            }
            navigate(`/application/details/${id}`);
        }
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }
        try {
            await ApplicationsService.remove(itemToRemove);
            onCancelRemove();
            toast.success(t('messages.record_delete_success'));
            setCriteria({ ...criteria, page: 1 })
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete applications', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    useEffect(() => {
        getData().catch(console.error);
    }, [criteria]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFilters({ ...filters, [key]: value });
    }
    function clearFilters() {
        setFilters({ allIn: '' });
    }
    const onSearch = () => {
        setCriteria({ ...criteria, allIn: filters.allIn });
    }

    return (
        <ScreenTitle title={t('applications.title')}>
            <Breadcrumb>
                <Breadcrumb.Item>{t('menu.configuration')}</Breadcrumb.Item>
                <Breadcrumb.Item active>{t('applications.title')}</Breadcrumb.Item>
            </Breadcrumb>
            <Container fluid>
                <Card className={styles.filters}>
                    <Card.Body>
                        <Stack direction="horizontal" gap={3} >
                            <Form.Control type="text" value={filters.allIn} className="me-auto" name="allIn" onChange={handleChange} placeholder={t('common.write_here')} />
                            <Button variant="secondary" onClick={onSearch}>{t('common.search')}</Button>
                            <div className="vr" />
                            <Button variant="outline-danger" onClick={clearFilters}>{t('common.clear')}</Button>
                            <Button variant="primary" onClick={() => navigate('/application/new/')}>{t('common.new')}</Button>
                        </Stack>
                    </Card.Body>
                </Card>
            </Container>
            <Container fluid>
                <ListingTable
                    columns={tableColumns}
                    rows={applicationsPage?.items || []}
                    onRowClick={(row, _, event) => onRowClick(event, row.id)}
                    allowHover={true}
                    initialSearch={{ colField: 'c.name', isOrderAsc: true }}
                />
                <PaginationWithInfo
                    itemName={t('applications.title')}
                    currentPage={applicationsPage?.currentPage ?? 1}
                    pageItems={applicationsPage?.items.length || 0}
                    totalItems={applicationsPage?.totalItems || 0}
                    onChange={page => setCriteria({ ...criteria, page })}
                />
            </Container>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })} />
        </ScreenTitle>
    );
};

export default ApplicationsScreen;
