import React, { ReactElement } from 'react';
import ReactModal from 'react-modal';
import styles from './Modal.module.scss';

type Props = {
    children: ReactElement | ReactElement[];
} & ReactModal.Props;

const Modal: React.FC<Props> = ({ children, ...props }: Props) => {
    const customStyles = {
        content: {
            backgroundColor: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
            outline: '0 none',
            ...(props.style?.content ? props.style.content : {}),
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            ...(props.style?.overlay ? props.style.overlay : {})
        },
    };

    return (
        <ReactModal
            {...props}
            style={{
                ...(props.style ? props.style : {}),
                ...customStyles,

            }}
            className={`${styles.content} ${props.className}`}
        >
            {children}
        </ReactModal >
    );
};

export default Modal;
