/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { DATE_TIME_FORMAT_DEFAULT } from 'Config';
import { LogDto } from 'api/logs/models/LogDto';
import moment from 'moment';
import { Badge, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
    log: LogDto | null
};

const LogScreen: React.FC<Props> = ({ log }: Props) => {
    const { t } = useTranslation();

    const color = (level: string | undefined): string => {
        if (!level) {
            return 'secondary';
        }

        switch (level) {
            case 'Warning':
                return 'warning';
            case 'Error':
                return 'danger';
            case 'Critical':
                return 'danger';
            default:
                return 'info'
        }
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                            <Badge bg={color(log?.level)}>{t(`logs.levels.${log?.level}`)}</Badge>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('logs.list.date')}</Form.Label>
                            <Form.Control type="text" placeholder={t('logs.list.date')} readOnly={true} defaultValue={moment(log?.date).format(DATE_TIME_FORMAT_DEFAULT)} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('logs.list.user')}</Form.Label>
                            <Form.Control type="text" placeholder={t('logs.list.user')} readOnly={true} defaultValue={log?.user} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('logs.list.message')}</Form.Label>
                            <Form.Control as="textarea" rows={3} readOnly={true} defaultValue={log?.message} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('logs.list.additional_data')}</Form.Label>
                            <Form.Control as="textarea" rows={3} readOnly={true} defaultValue={log?.additionalData} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>{t('logs.list.stack_trace')}</Form.Label>
                            <Form.Control as="textarea" rows={4} readOnly={true} defaultValue={log?.stackTrace} />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default LogScreen;
