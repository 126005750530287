import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { ApplicationSearchCriteria } from './models/ApplicationSearchCriteria';
import { ApplicationDto } from './models/ApplicationDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class ApplicationsService {
    public getList(criteria: ApplicationSearchCriteria) {
        return Http.get<Paged<ApplicationDto>>('applications', criteria);
    }

    public getById(id: string) {
        return Http.get<ApplicationDto>('applications/' + id);
    }

    public getMyApplications() {
        return Http.get<SelectValueLabel[]>('applications/getapplications');
    }

    public create(model: ApplicationDto) {
        return Http.post<string>('applications', model);
    }

    public update(model: ApplicationDto) {
        return Http.put<string>('applications/' + model.id, model);
    }

    public remove(model: ApplicationDto) {
        console.log(model);
        return Http.put(`applications/${model.id}/deactivate`, model);
    }
}

export default new ApplicationsService();
