/* eslint-disable @typescript-eslint/brace-style */
import { Paged } from 'api/common/types/Page';
import LogsService from 'api/logs/LogsService';
import { LogDto } from 'api/logs/models/LogDto';
import { LogSearchCriteria } from 'api/logs/models/LogSearchCriteria';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import Logger from 'common/services/Logger';
import { DATE_TIME_FORMAT_DEFAUT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LogsScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import toast from 'react-hot-toast';
import { Button, Container, Stack, Form, Card, Breadcrumb, Badge } from 'react-bootstrap';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { LogLevel } from 'api/logs/enums/LogLevel';
import ApplicationsService from 'api/applications/ApplicationsService';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { LogDates } from 'api/logs/enums/LogDates';
import moment from 'moment';
import LogScreen from 'screens/log/LogScreen';
import Modal from 'common/components/modal/Modal';
import Loading from 'common/services/Loading';

type Props = {
};

const LogsScreen: React.FC<Props> = () => {
    const { t } = useTranslation();
    const [criteria, setCriteria] = useState<LogSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'desc',
        orderColumn: 'logs.date'
    });
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [logsPage, setLogsPage] = useState<Paged<LogDto>>();
    const [selectedItem, setSelectedItem] = useState<LogDto | null>(null);
    const [showLogModal, setShowLogModal] = useState<boolean>(false);
    const [applications, setApplications] = useState<SelectValueLabel[]>();
    const [levels] = useState<SelectValueLabel[]>([
        { value: LogLevel.Trace, label: t('logs.levels.Trace') ?? '' },
        { value: LogLevel.Debug, label: t('logs.levels.Debug') ?? '' },
        { value: LogLevel.Information, label: t('logs.levels.Information') ?? '' },
        { value: LogLevel.Warning, label: t('logs.levels.Warning') ?? '' },
        { value: LogLevel.Error, label: t('logs.levels.Error') ?? '' },
        { value: LogLevel.Critical, label: t('logs.levels.Critical') ?? '' }]);

    const [dates] = useState<SelectValueLabel[]>([
        { value: LogDates.Last7Days, label: t('logs.dates.Last7Days') ?? '' },
        { value: LogDates.Last30Days, label: t('logs.dates.Last30Days') ?? '' },
        { value: LogDates.Last90Days, label: t('logs.dates.Last90Days') ?? '' },
        { value: LogDates.Last6Months, label: t('logs.dates.Last6Months') ?? '' },
        { value: LogDates.Last12Months, label: t('logs.dates.Last12Months') ?? '' }]);

    const filtersInitialState = {
        level: '',
        allIn: '',
        applicationId: '',
        date: LogDates.Last7Days,
        startDate: moment(new Date()).add(-7, 'days').toISOString(),
        endDate: moment(new Date()).toISOString()
    };
    const [filters, setFilters] = useState(filtersInitialState);

    const getData = async () => {
        try {
            Loading.show();
            const page = await LogsService.getList(criteria);
            setLogsPage(page);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get logs list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const color = (level: string | undefined): string => {
        if (!level) {
            return 'secondary';
        }
        switch (level) {
            case 'Warning':
                return 'warning';
            case 'Error':
                return 'danger';
            case 'Critical':
                return 'danger';
            default:
                return 'info'
        }
    }

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<LogDto>[] = [
        {
            field: 'message',
            name: t('logs.list.message') ?? '',
            onSearch: onTableFilter,
            searchField: 'message'
        },
        {
            field: 'level',
            name: t('logs.list.level') ?? '',
            onSearch: onTableFilter,
            searchField: 'level',
            renderCell: row => (<Badge bg={color(row?.level)}>{t(`logs.levels.${row?.level}`)}</Badge>)
        },
        {
            field: 'date',
            name: t('logs.list.date') ?? '',
            onSearch: onTableFilter,
            searchField: 'date',
            renderCell: row => (<DateFormat value={row.date} format={DATE_TIME_FORMAT_DEFAUT} />),
        },
        {
            field: 'applicationName',
            name: t('logs.list.application') ?? '',
            onSearch: onTableFilter,
            searchField: 'applicationName'
        }
    ];

    const onRowClick = (log: LogDto) => {
        showItemDialog(log);
    }

    useEffect(() => {
        if (!isLoaded) {
        ApplicationsService.getMyApplications()
            .then((data) => {
                setApplications(data);
                if (data && data.length > 0 && data[0]) {
                    setFilters({ ...filters, applicationId: data[0].value ?? '' });
                }
            }).finally(() => {
                setIsLoaded(true);
            });
        }
    }, []);

    useEffect(() => {
        if (isLoaded) {
            onSearch();
        }
    }, [isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            getData();
        }
    }, [criteria]);

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFilters({ ...filters, [key]: value });
    }

    function handleChangeDates(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        let date = moment(new Date()).add(-7, 'days').toISOString();
        switch (value) {
            case 'Last30Days' :
                date = moment(new Date()).add(-30, 'days').toISOString();
            break;
            case 'Last90Days' :
                date = moment(new Date()).add(-90, 'days').toISOString();
            break;
            case 'Last6Months' :
                date = moment(new Date()).add(-6, 'months').toISOString();
            break;
            case 'Last12Months' :
                date = moment(new Date()).add(-12, 'months').toISOString();
            break;
            default:
                date = moment(new Date()).add(-7, 'days').toISOString();
        }
        setFilters({ ...filters, startDate: date, date: value });
    }

    function clearFilters() {
        setFilters({ ...filtersInitialState, applicationId: applications && applications.length > 0 && applications[0] ? `${applications[0].value}` : '' });
    }

    const search = () => {
        setCriteria({ ...criteria, page: 1, ...filters });
    }

    const onSearch = () => {
        setCriteria({ ...criteria, ...filters });
    }

    const showItemDialog = async (item: LogDto) => {
        setSelectedItem(item);
        setShowLogModal(true);
    }

    const onHide = () => {
        setSelectedItem(null);
        setShowLogModal(false);
    };

    return (
        <ScreenTitle title={t('logs.title')}>
            <Breadcrumb>
                <Breadcrumb.Item>{t('menu.configuration')}</Breadcrumb.Item>
                <Breadcrumb.Item active>{t('logs.title')}</Breadcrumb.Item>
            </Breadcrumb>
            <Container fluid>
                <Card className={styles.filters}>
                    <Card.Body>
                        <Stack direction="horizontal" gap={3}>
                            <Form.Control type="text" value={filters.allIn} className="me-auto" name="allIn" onChange={handleChange} placeholder={t('common.write_here')} />
                            <Form.Select name="level" value={filters.level}
                                onChange={(e: any) => { handleChange(e); }}>
                                <option value={''}>{t('common.select_option')}</option>
                                {
                                    levels.map(l => <option key={l.value} value={l.value}>{l.label}</option>)
                                }
                            </Form.Select>
                            <Form.Select name="applicationId" value={filters.applicationId}
                                onChange={(e: any) => { handleChange(e); }}>
                                {
                                    applications?.map(l => <option key={l.value} value={l.value}>{l.label}</option>)
                                }
                            </Form.Select>

                            <Form.Select value={filters.date}
                                onChange={(e: any) => { handleChangeDates(e); }}>
                                {
                                    dates?.map(l => <option key={l.value} value={l.value}>{l.label}</option>)
                                }
                            </Form.Select>

                            <Button variant="secondary" onClick={search}>{t('common.search')}</Button>
                            <div className="vr" />
                            <Button variant="outline-danger" onClick={clearFilters}>{t('common.clear')}</Button>
                        </Stack>
                    </Card.Body>
                </Card>
            </Container>
            <Container fluid>
                <ListingTable
                    columns={tableColumns}
                    rows={logsPage?.items || []}
                    onRowClick={(row) => onRowClick(row)}
                    allowHover={true}
                    initialSearch={{ colField: 'c.name', isOrderAsc: true }}
                />
                <PaginationWithInfo
                    itemName={t('logs.title')}
                    currentPage={logsPage?.currentPage ?? 1}
                    pageItems={logsPage?.items.length || 0}
                    totalItems={logsPage?.totalItems || 0}
                    onChange={page => setCriteria({ ...criteria, page: page })}
                />
                <Modal isOpen={showLogModal} onRequestClose={onHide} className={styles.modalFull}>
                    <LogScreen log={selectedItem}></LogScreen>
                </Modal>
            </Container>
        </ScreenTitle>
    );
};

export default LogsScreen;
