import styles from './NavBar.module.scss';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import Logo from 'assets/svg/logo.svg';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { ApplicationPaths, LOGGER_LOG_TYPE } from 'Config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LanguagesService from 'api/languages/LanguagesService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import AccountService from 'api/account/AccountService';
import { setProfile } from 'store/authentication/action';
import Logger from 'common/services/Logger';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [langs, setLangs] = useState<any[]>([]);
    const [lang, setLang] = useState<any>([]);

    const changeLang = (lang: SelectValueLabel) => {
        AccountService.changeLang(lang)
            .finally(() => {
                getProfile().catch(console.error);
                setLang(lang);
            });
    }

    useEffect(() => {
        LanguagesService.GetAllForSelectItem()
            .then((data) => {
                setLangs(data);
                setLang(data.filter(l => l.value === loggedUser?.languageId)[0]);
            })
            .catch(() => Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get languages'));
    }, []);

    const getProfile = async () => {
        try {
            const profile = await AccountService.profile();
            dispatch(setProfile(profile) as any);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get user profile', error);
        }
    }

    return <Navbar className={`main-header ${styles.NavBar}`} expand="lg" data-bs-theme="dark">
        <Container fluid style={{ justifyContent: 'flex-end' }}>
            <Navbar.Collapse style={{ flexGrow: 'unset', padding: '0 10px' }}>
                <Nav>
                    <NavDropdown title={loggedUser?.realName} menuVariant="dark">
                        <NavDropdown.Item onClick={() => navigate('/account/details')}>{t('common.profile')}</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => navigate(`${ApplicationPaths.LogOut}`)}>{t('common.logout')}</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
            {lang && <Navbar.Collapse style={{ flexGrow: 'unset', padding: '0 10px' }}>
                <Nav>
                    <NavDropdown title={lang?.fullLabel} menuVariant="dark">
                        {langs.map(l => {
                            return <NavDropdown.Item onClick={() => changeLang(l)} key={l.value}>{l.fullLabel}</NavDropdown.Item>
                        })}
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
            }
            <Navbar.Brand href="http://www.sparkleit.pt"> <img src={Logo} className={styles.logo} /></Navbar.Brand>
        </Container>
    </Navbar>;
};

export default NavBar;
