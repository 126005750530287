import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import pt from '../../assets/translations/pt.json';
import en from '../../assets/translations/en.json';
import { DEFAULT_LANGUAGE, STORAGE, DEFAULTNS, LOGGER_PRINT_LOGS } from '../../Config';
import Storage from './Storage';

const listLanguages = ['pt', 'fr', 'en'];

const languageDetector: any = {
    type: 'languageDetector',
    async: true,
    detect: (callback: (lng: string) => void) => {
        let language = Storage.get(STORAGE.CURRENT_LOCALE);
        if (language == null) {
            const browserLanguage = window.navigator.language.toLowerCase().split('-')[0];
            const langByBrowser = listLanguages.find(x => browserLanguage.includes(x.toLowerCase()));
            language = langByBrowser ?? DEFAULT_LANGUAGE;
        }
        return callback(language);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    init: () => { },
    cacheUserLanguage: (language: string) => {
        Storage.set(STORAGE.CURRENT_LOCALE, language);
    }
};

const config: InitOptions = {
    fallbackLng: 'en',
    debug: Boolean(LOGGER_PRINT_LOGS),
    initImmediate: false,
    load: 'languageOnly',
    ns: [DEFAULTNS],
    defaultNS: DEFAULTNS,
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: false
    },
    resources: {
        pt: {
            [DEFAULTNS]: pt,
        },
        en: {
            [DEFAULTNS]: en
        }
    }
};

i18n
    .use(initReactI18next)
    .use(languageDetector)
    .init(config);

export default i18n;
